<template>
  <!-- 系统设置 - 联系人公告-详情 -->
  <div class="pageContol aeAdvisoryNotice">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
       <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="cancel()">招工采集H5</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{
              this.$route.query.stu == "edit" ? "修改页面" : this.$route.query.stu == "add" ? "新增页面" : "查看页面"
            }}</a>
        </span>
      </div>
      <!-- 编辑内容 -->
      <div class="framePage-body">
        <el-form ref="form" :rules="rules" :model="contactData" label-width="120px">
          <el-form-item label="姓名" prop="name">
            <el-input
                style="width:284px"
                size="small"
                placeholder="请输入联系人姓名"
                v-model="contactData.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile">
            <el-input
                style="width:284px"
                size="small"
                placeholder="请输入手机号码"
                v-model="contactData.mobile"
            ></el-input>
          </el-form-item>
<!--          <el-form-item label="行政区划">-->
<!--            <el-cascader-->
<!--                v-model="contactData.areaId"-->
<!--                style="width:284px"-->
<!--                :options="areatreeList"-->
<!--                :props="propsarea"-->
<!--                clearable-->
<!--                filterable-->
<!--                size="small"-->
<!--                placeholder="请选择行政区划"-->
<!--            ></el-cascader>-->
<!--          </el-form-item>-->
          <el-form-item
              label="二维码"
              prop="thumbnail"
              :class="[rotationId ? 'item-disabled' : '']"
              required
          >
            <el-upload
                :on-change="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload2"
                :http-request="$requestMine"
                :show-file-list="false"
                class="img-el-upload"
                action
                accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                  :src="contactData.thumbnail || require('@/assets/develop.png')"
                  fit="contain"
                  class="imgCenter"
              ></el-image>
            </el-upload>
            <p
                style="color:#f46173;line-height:25px;"
            >请上传 200*200 （长 X 宽）像素的PNG,JPG格式的图片，且文件大小不能超过1MB！</p>
          </el-form-item>
          <el-form-item class="czzjf">
            <el-button class="bgc-bv" @click="cancel">取消</el-button>
            <el-button type="primary" class="bgc-bv" @click="addEdit('form')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
// import E from "wangeditor";
import UploadPic from "@/mixins/UploadPic";

import { validateURL } from "@/utils/common"; // 校验url

// let editor = {};
export default {
  name: "createRecruitmentCollectionH5",
  mixins: [UploadPic],
  data() {
    return {
      // 联系人列表传过来的id
      contactId: "",
      // 消息详情数据
      contactData: {
        name: "", // 联系人名称s
        mobile: "",
        thumbnail: "", //图片地址
        fileKey: "", //上传的图片
      },
      // 校验输入
      rules: {
        name: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        thumbnail: [
          { required: true, message: "请上传二维码", trigger: "change" }
        ]
      }
    };
  },
  mounted() {
  },
  computed: {},
  created() {
    // 初始化 - 联系人列表传过来的id
    if (this.$route.query.contactId) {
      this.contactId = this.$route.query.contactId;
      this.$post("/biz/recruitment/contact/getInfo", {
        contactId: this.contactId
      }).then(res => {
        (this.contactData.name = res.data.userName),
        (this.contactData.mobile = res.data.mobile),
        // (this.contactData.fileKey = res.data.picPath),
        (this.contactData.thumbnail = res.data.dimensionalBarcode)
        // (this.contactData.advisoryPath = res.data.advisoryPath)
      });
    }
  },
  methods: {
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
          ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
              res.raw.type
          ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
            .then(result => {
              this.contactData.thumbnail = result.data.fileURL || "";
              this.contactData.fileKey = result.data.fileKey || "";
            })
            .catch(() => {
              setTimeout(() => {
                this.$message({
                  type: "warning",
                  message: "上传失败"
                });
              }, 300);
            });
      }
    },
    // 取消
    cancel() {
      this.$router.push({
        path: "/web/workOrder/recruitmentCollectionH5",
        query: {
          refresh: true
        }
      });
    },
    // 添加/编辑
    addEdit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            userName: this.contactData.name,
            mobile: this.contactData.mobile,
            dimensionalBarcode: this.contactData.thumbnail
          };
          if (this.contactId) {
            data.contactId = this.contactId;
            this.$post("/biz/recruitment/contact/modify", data).then(res => {
              this.$message.success(res.message);
              this.$router.push({
                path: "/web/workOrder/recruitmentCollectionH5",
                query: {
                  refresh: true
                }
              });
            });
          } else {
            this.$post("/biz/recruitment/contact/insert", data).then(res => {
              this.$message.success(res.message);
              this.$router.push({
                path: "/web/workOrder/recruitmentCollectionH5",
                query: {
                  refresh: true
                }
              });
            });
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 18px;
}
.czzjf {
  width: 150px;
  margin: auto;
}
.czzjf /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
</style>
